import { graphql } from 'gatsby';

import Img from 'gatsby-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
// Modules
import React, { useEffect } from 'react';
import Footer from '@components/Footer/Footer';
import HeaderMinimal from '@components/HeaderMinimal/HeaderMinimal';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import ProvidersListComponent from '@components/ProvidersList/ProvidersListComponent';
import Seo from '@components/seo';
import SideSection from '@components/SideSection/SideSection';
import { useLocale, useTracking } from '@hooks';
import AboutTeam from '@components/AboutTeam';
import { withLocale, withTracking } from '@hocs';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "about/header-about-us-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "about/header-about-us-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        coachImage: file(relativePath: { eq: "coaches_image.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 910) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aboutImage: file(relativePath: { eq: "about/about-behind-aepsy.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 910) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aboutPicture1: file(relativePath: { eq: "about/about-picture-1.jpg" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 455) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamNicolas: file(relativePath: { eq: "team/team_nicolas.png" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamAdonis: file(relativePath: { eq: "team/team_adonis.png" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamHiep: file(relativePath: { eq: "team/team_hiep.png" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamHan: file(relativePath: { eq: "team/team_han.png" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamRomina: file(relativePath: { eq: "team/team_romina.png" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamFq: file(relativePath: { eq: "team/team-fg.png" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamAg: file(relativePath: { eq: "team/team-ag.png" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamRz: file(relativePath: { eq: "team/team-rz.png" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamVd: file(relativePath: { eq: "team/team-vd.png" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamNw: file(relativePath: { eq: "team/team-nw.png" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamGc: file(relativePath: { eq: "team/team-gc.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamCj: file(relativePath: { eq: "team/team-cj.png" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
             teamRk: file(relativePath: { eq: "team/team-rk.jpg" }) {
            childImageSharp {
                fluid(quality: 80, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamNd: file(relativePath: { eq: "team/team-nd.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamAk: file(relativePath: { eq: "team/team-ak.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        teamDh: file(relativePath: { eq: "team/team-dh.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

const AboutPage = ({ location, data: static_images }) => {
    const { t } = useTranslation();
    const { getAppLinkWithCurrentLang } = useLocale();
    const header_image_sources = [
        static_images.headerMobileImage.childImageSharp.fluid,
        {
            ...static_images.headerDesktopImage.childImageSharp.fluid,
            media: `(min-width: 750px)`,
        },
    ];

    const { trackPageView } = useTracking();
    useEffect(() => {
        trackPageView();
    }, []);

    return (
        <Layout>
            <Seo
                title={t('about.seo.title')}
                description={t('about.seo.description')}
            />
            <div className="global_theme-green">
                <HeaderNav theme="white" location={location.pathname} />
                <Section theme="light">
                    <HeaderMinimal
                        theme="light"
                        title={t('about.hero.header.title')}
                        btnPrimary={
                            <AnchorLink offset={() => 40} href="#mehr">
                                <Button
                                    variant="primary"
                                    theme="white"
                                    label={t('learn.more')}
                                    size="l"
                                />
                            </AnchorLink>
                        }
                        imageSrc={header_image_sources}
                    />
                </Section>

                {/* Die Gründungsgeschichte */}
                <div id="mehr">
                    <Section theme="light" spacing="m" container="large">
                        <SideSection
                            // direction="reverse"
                            bgContent={false}
                            title={t('about.intro.statement.title')}
                            text={
                                <>
                                    <Text>
                                        <Trans
                                            i18nKey={
                                                'about.intro.statement.text'
                                            }
                                            components={{ br: <br /> }}
                                        />
                                    </Text>
                                </>
                            }
                            visual={
                                <Img
                                    fluid={
                                        static_images.aboutPicture1
                                            .childImageSharp.fluid
                                    }
                                    alt="About image"
                                />
                            }
                        />
                    </Section>
                </div>

                {/* Quote */}
                <Section container="short" spacing="xl" theme="dark">
                    <div className="g_center">
                        <Title align="center" size="xl" theme="white">
                            "{t('about.intro.quote.text')}"
                        </Title>
                        <Text size="l" theme="white" align="center">
                            {t('about.intro.quote.author')}
                        </Text>
                    </div>
                </Section>

                <AboutTeam staticImages={static_images} />

                {/* About us */}
                <Section
                    theme="light"
                    spacingBottom="s"
                    spacingTop="l"
                    container="large"
                >
                    <SideSection
                        direction="reverse"
                        title={t('about.why.booking.with.aepsy.title')}
                        text={t('about.why.booking.with.aepsy.text')}
                        link={
                            <>
                                <a
                                    href={getAppLinkWithCurrentLang(
                                        process.env.GATSBY_START_FULL_FUNNEL_URL
                                    )}
                                >
                                    <Button
                                        label={t('find.pyschologist.cta.label')}
                                        align="center"
                                        isMobileFullsize
                                        variant="inline"
                                        iconArrow
                                    />
                                </a>
                            </>
                        }
                        visual={
                            <Img
                                fluid={
                                    static_images.coachImage.childImageSharp
                                        .fluid
                                }
                                alt="Aepsy Experts image"
                            />
                        }
                    />
                </Section>

                {/* Our providers */}
                <Section theme="light" spacingTop="l" spacingBottom="m">
                    <ProvidersListComponent />
                </Section>

                {/* Footer */}
                <Footer variant="extended" />
            </div>
        </Layout>
    );
};

export default withTracking(withLocale(AboutPage));
